import React, { useEffect, useState } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import PropTypes from 'prop-types';
import { parcelShopsFindAll } from '../helpers/API/closerAPI';
import { ReactComponent as ParcelShopIcon } from '../assets/img/icons/ps-locker.svg';
import { ReactComponent as ParcelShopShopIcon } from '../assets/img/icons/ps-shop.svg';

const ParcelShopMarker = ({ parcelShop }) => {
  if (!parcelShop?.address?.location?.geometry?.coordinates) {
    console.warn('Marker de un Parcelshop sin geometría válida:', parcelShop);
    return null;
  }

  const position = {
    lat: parcelShop.address.location.geometry.coordinates[1],
    lng: parcelShop.address.location.geometry.coordinates[0]
  };

  return (
    <AdvancedMarker position={position} title={parcelShop.name}>
      <div style={{ fontSize: '35px' }}>
        {parcelShop.type === 'shop' && <ParcelShopShopIcon />}
        {parcelShop.type === 'locker' && <ParcelShopIcon />}
      </div>
    </AdvancedMarker>
  );
};

const ParcelShopMarkers = () => {
  const [parcelShops, setParcelShops] = useState(() => {
    const stored = JSON.parse(
      localStorage.getItem('parcelShopsData') || '{"shops":[],"token":""}'
    );
    return stored.shops;
  });

  useEffect(() => {
    const fetchParcelShops = async () => {
      try {
        const currentToken = new URLSearchParams(window.location.search).get(
          'token'
        );
        const stored = JSON.parse(
          localStorage.getItem('parcelShopsData') || '{"shops":[],"token":""}'
        );

        if (stored.token === currentToken && stored.shops.length > 0) {
          return setParcelShops(stored.shops);
        }

        const newParcelShops = (await parcelShopsFindAll()) || [];
        setParcelShops(newParcelShops);

        localStorage.setItem(
          'parcelShopsData',
          JSON.stringify({
            shops: newParcelShops,
            token: currentToken
          })
        );
      } catch (error) {
        console.info('Error al obtener puntos de entrega:', error);
      }
    };

    fetchParcelShops();
  }, []);

  return (
    <>
      {parcelShops
        .filter(parcelShop => parcelShop.type !== 'agency')
        .map(parcelShop => (
          <ParcelShopMarker
            key={parcelShop.id || parcelShop._id || parcelShop.code}
            parcelShop={parcelShop}
          />
        ))}
    </>
  );
};

ParcelShopMarker.propTypes = {
  parcelShop: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    address: PropTypes.shape({
      country: PropTypes.string,
      postalCode: PropTypes.string,
      location: PropTypes.shape({
        geometry: PropTypes.shape({
          type: PropTypes.string.isRequired,
          coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
        }).isRequired,
        precision: PropTypes.string
      }).isRequired,
      locality: PropTypes.string,
      street: PropTypes.string
    }).isRequired
  }).isRequired
};

export default ParcelShopMarkers;
