import RBush from 'rbush';
import { GeometryTypes } from '../dataGLS/polygon';

const tree = new RBush();

// Calculate cross product between three points
const crossProduct = (p1, p2, p3) => {
  return (
    (p2.lng - p1.lng) * (p3.lat - p1.lat) -
    (p2.lat - p1.lat) * (p3.lng - p1.lng)
  );
};

//Verify if two segments intersects
const doSegmentsIntersect = (p1, p2, p3, p4) => {
  //Calculate cross productos to determine point orientation
  const d1 = crossProduct(p3, p4, p1);
  const d2 = crossProduct(p3, p4, p2);
  const d3 = crossProduct(p1, p2, p3);
  const d4 = crossProduct(p1, p2, p4);

  //Check if orientations are different
  return d1 * d2 < 0 && d3 * d4 < 0;
  // True segments intersect
  // False segments DON'T intersect
};

// Detect if a polygon has self intersections
export const hasOverlappingLines = polygon => {
  const segments = [];
  // Create segments from polygon points
  for (let i = 0; i < polygon.length; i++) {
    const p1 = polygon[i];
    const p2 = polygon[(i + 1) % polygon.length];
    // Store segment with bounding box for spatial indexing
    segments.push({
      minX: Math.min(p1.lng, p2.lng),
      minY: Math.min(p1.lat, p2.lat),
      maxX: Math.max(p1.lng, p2.lng),
      maxY: Math.max(p1.lat, p2.lat),
      segment: [p1, p2]
    });
  }

  // Check each segment for intersection with others
  return segments.some((seg, i) => {
    tree.clear();
    // Load all segments except the current one into the spatial index
    tree.load(segments.filter((_, j) => j !== i));
    // Find candidate segments that might intersect with the current segment
    const candidates = tree.search(seg);
    // Check if any candidate segment actually intersects with the current segment
    return candidates.some(c =>
      doSegmentsIntersect(
        seg.segment[0],
        seg.segment[1],
        c.segment[0],
        c.segment[1]
      )
    );
  });
};

// Calculate polygon's area. A positive area for counter-clockwise direction path
const calculateArea = points => {
  let area = 0;
  for (let i = 0; i < points.length; i++) {
    let j = (i + 1) % points.length;
    area += (points[j].lng - points[i].lng) * (points[j].lat + points[i].lat);
  }
  return area / 2;
};

//Reorders points to set second+ polygons in oposite direction to first
export const reorderPolygonPointsHole = arr => {
  if (arr.length <= 1) return arr;
  // Set first polygon direction
  const outerDirection = calculateArea(arr[0]) < 0; // true for counter-clockwise, false for clockwise

  return arr.map((subArray, index) => {
    if (index === 0) {
      return subArray; // Maintain first.
    }
    // For holes, invert order if direction is the same as first polygon
    const innerDirection = calculateArea(subArray) < 0;
    return innerDirection === outerDirection ? subArray.reverse() : subArray;
  });
};

export const validatePolygonPath = coordinates => {
  // Verificar que haya al menos 4 puntos
  if (!coordinates || coordinates.length < 4) {
    console.warn('El polígono debe tener al menos 4 puntos');
    return false;
  }

  // Verificar que el primer y último punto sean iguales
  const firstPoint = coordinates[0];
  const lastPoint = coordinates[coordinates.length - 1];
  if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
    // Cerrar automáticamente el polígono añadiendo el primer punto al final
    coordinates.push([...firstPoint]);
  }

  return true;
};

// Modificar la función existente para usar la validación
export const coordinatesArrayToObject = (father, propertyName) => {
  let formattedCoordinates = [];
  switch (father[propertyName].type) {
    case GeometryTypes.Polygon:
      formattedCoordinates = [
        father[propertyName].coordinates
          .map(coordinate => {
            if (!validatePolygonPath(coordinate)) {
              return [];
            }
            return coordinate.map(c => {
              return { lat: c[1], lng: c[0] };
            });
          })
          .filter(coords => coords.length >= 4)
      ];
      break;
    case GeometryTypes.MultiPolygon:
      formattedCoordinates = father[propertyName].coordinates.map(polygon => {
        return polygon
          .map(coordinate => {
            if (!validatePolygonPath(coordinate)) {
              return [];
            }
            return coordinate.map(c => {
              return { lat: c[1], lng: c[0] };
            });
          })
          .filter(coords => coords.length >= 4);
      });
      break;
  }
  return formattedCoordinates;
};

const closePolygonSamePoint = (coordinates, type) => {
  switch (type) {
    case GeometryTypes.Polygon:
      if (
        JSON.stringify(coordinates[0][0]) !==
        JSON.stringify(coordinates[0][coordinates[0].length - 1])
      ) {
        coordinates[0].push(coordinates[0][0]);
      }
      break;
    case GeometryTypes.MultiPolygon:
      coordinates.forEach((c, index) => {
        if (JSON.stringify(c[0][0]) !== JSON.stringify(c[0][c[0].length - 1])) {
          coordinates[index][0].push(c[0][0]);
        }
      });
  }
  return coordinates;
};

export const coordinatesObjectToArray = (father, propertyName) => {
  let coordinates = structuredClone(father[propertyName].coordinates);
  let formattedCoordinates = [];
  //Set last point equal to first if not is equal
  coordinates = closePolygonSamePoint(coordinates, father[propertyName].type);

  switch (father[propertyName].type) {
    case GeometryTypes.Polygon:
      formattedCoordinates = coordinates.map(coordinate => {
        return coordinate.map(c => {
          return [c.lng, c.lat];
        });
      });
      break;
    case GeometryTypes.MultiPolygon:
      formattedCoordinates = coordinates.map(polygon => {
        return polygon.map(coordinate => {
          return coordinate.map(c => {
            return [c.lng, c.lat];
          });
        });
      });
      break;
  }
  return formattedCoordinates;
};
