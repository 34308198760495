import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';

const LoadingScreen = props => {
  const { t } = props;
  return (
    <div
      className={'position-absolute vh-100 vw-100 top-0 bg-opacity-50 bg-dark'}
      style={{ zIndex: 2000 }}
    >
      <div
        className={
          'position-absolute top-50 start-50 translate-middle text-center text-light'
        }
      >
        <div
          className="spinner-border"
          style={{ width: '3rem', height: '3rem' }}
          role="status"
        >
          <span className="visually-hidden">{t('Loading data')}</span>
        </div>
        <p>{t('Loading data')}</p>
      </div>
    </div>
  );
};

LoadingScreen.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(LoadingScreen);
