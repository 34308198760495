import React, { useEffect, useState } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import PropTypes from 'prop-types';
import { agenciesFindCurrent } from '../helpers/API/closerAPI';
import { ReactComponent as AgencyIcon } from '../assets/img/icons/mi-agencia.svg';

const AgencyMarker = ({ agency }) => {
  if (!agency?.address?.location?.geometry?.coordinates) {
    console.warn('Agencia sin coordenadas válidas:', agency);
    return null;
  }

  const position = {
    lat: agency.address.location.geometry.coordinates[1],
    lng: agency.address.location.geometry.coordinates[0]
  };

  return (
    <AdvancedMarker position={position} title={agency.name}>
      <AgencyIcon />
    </AdvancedMarker>
  );
};

const AgencyMarkers = () => {
  const [agencies, setAgencies] = useState(() => {
    const stored = JSON.parse(
      localStorage.getItem('agenciesData') || '{"agencies":[],"token":""}'
    );
    return stored.agencies;
  });

  useEffect(() => {
    const fetchAgencies = async () => {
      try {
        const currentToken = new URLSearchParams(window.location.search).get(
          'token'
        );
        const stored = JSON.parse(
          localStorage.getItem('agenciesData') || '{"agencies":[],"token":""}'
        );

        if (stored.token === currentToken && stored.agencies.length > 0) {
          return setAgencies(stored.agencies);
        }

        const newAgencies = (await agenciesFindCurrent()) || [];
        setAgencies(newAgencies);

        localStorage.setItem(
          'agenciesData',
          JSON.stringify({
            agencies: newAgencies,
            token: currentToken
          })
        );
      } catch (error) {
        console.info('Error al obtener agencias:', error);
      }
    };

    fetchAgencies();
  }, []);

  return (
    <>
      {agencies.map(agency => (
        <AgencyMarker key={agency.code} agency={agency} />
      ))}
    </>
  );
};

AgencyMarker.propTypes = {
  agency: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      location: PropTypes.shape({
        geometry: PropTypes.shape({
          coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
        }).isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default AgencyMarkers;
