import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';
import { polygonOptions } from '../dataGLS/polygon';

export function useDrawingManager(initialValue = null) {
  const map = useMap();
  const drawing = useMapsLibrary('drawing');

  const [drawingManager, setDrawingManager] = useState(initialValue);

  useEffect(() => {
    if (!map || !drawing) return;

    // https://developers.google.com/maps/documentation/javascript/reference/drawing
    const newDrawingManager = new drawing.DrawingManager({
      map,
      drawingControl: false,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          //window.google.maps.drawing.OverlayType.MARKER,
          //window.google.maps.drawing.OverlayType.CIRCLE,
          //window.google.maps.drawing.OverlayType.POLYLINE,
          //window.google.maps.drawing.OverlayType.RECTANGLE,
          window.google.maps.drawing.OverlayType.POLYGON
        ]
      },
      markerOptions: {
        draggable: true
      },
      circleOptions: {
        editable: true
      },
      polygonOptions,
      rectangleOptions: {
        editable: true,
        draggable: true
      },
      polylineOptions: {
        editable: true,
        draggable: true
      }
    });

    setDrawingManager(newDrawingManager);

    return () => {
      newDrawingManager.setMap(null);
    };
  }, [drawing, map]);

  return drawingManager;
}
