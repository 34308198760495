import { useEffect, useRef } from 'react';

export const objectChange = (e, originalData, setOriginalData) => {
  const { name, value } = e.target;
  const newData = { ...originalData, [name]: value };
  setOriginalData(newData);
};

export const isJsonEmpty = obj => {
  return Object.keys(obj).length === 0;
};

export const getFPEType = stringDate => {
  if (!stringDate) return 0;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const date = new Date(stringDate);
  date.setHours(0, 0, 0, 0);

  const diffDays = Math.floor((date - today) / (1000 * 60 * 60 * 24));

  if (diffDays < 0) return -1; // Past
  if (diffDays > 0) return 1; // Future
  return 0; // Today
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
