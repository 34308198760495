import React, { useEffect, useState, useRef, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import {
  ControlPosition,
  Map,
  MapControl,
  useMap
} from '@vis.gl/react-google-maps';
import { useDrawingManager } from './useDrawingManager';
import { useDrawingManagerEvents } from './drawingManagerListeners';
import { MapContext } from '../providers/mapProvider';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { ClusterRenderer } from './ClusterRenderer';
import StopsMarkers from './StopsMarkers';
import AgencyMarkers from './AgencyMarkers';
import ParcelShopMarkers from './ParcelShopMarkers';
import { CreateZoneModes } from '../dataGLS/CloserModes';
import MapToolbar from './MapToolbar';

const CloserMap = () => {
  //const { i18n } = props;
  const map = useMap();
  const managerListener = useRef(null);
  const mapProvider = useContext(MapContext);
  const {
    createZoneMode,
    selectedZone,
    centerArea,
    stopsFiltered,
    mapBounds,
    mapState,
    setMapState
  } = mapProvider;
  const defaultCenter = {
    lat: 39.4398223,
    lng: -0.4720902
  };
  const [center, setCenter] = useState(defaultCenter);

  const mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    disableDefaultUI: true,
    fullscreenControl: false,
    streetViewControl: false,
    clickableIcons: false
  };

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const drawingManagerRef = useRef();
  drawingManagerRef.current = useDrawingManager();
  const selectedPolygonRef = useRef(null);

  managerListener.current = useDrawingManagerEvents(
    drawingManagerRef.current,
    mapProvider,
    selectedPolygonRef
  );
  /*
  Save for the futuro to change language
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
   */

  useEffect(() => {
    console.log('mapBounds', { mapBounds });
    map?.fitBounds(mapBounds);
  }, [mapBounds]);

  useEffect(() => {
    console.log('Zone Mode', createZoneMode);
    if (!drawingManagerRef.current) return;
    // Prevent selected polygons after exit edit zone
    if (managerListener.current) {
      managerListener.current.deselectAllPolygons();
    }
  }, [createZoneMode]);

  useEffect(() => {
    if (!centerArea) return;
    setCenter(centerArea);
  }, [centerArea]);

  useEffect(() => {
    //Update selectedZone in manager
    managerListener.current.setSelectedZone(selectedZone);
  }, [selectedZone]);

  return (
    <>
      <div
        className="map-container flex-grow-1"
        style={{ position: 'relative' }}
      >
        <Map
          mapId="CloserMap"
          defaultCenter={center}
          defaultZoom={1}
          mapContainerStyle={containerStyle}
          style={containerStyle}
          options={mapOptions}
        >
          {createZoneMode !== CreateZoneModes.DISABLED && (
            <MapControl position={ControlPosition.BOTTOM_CENTER}>
              <MapToolbar
                drawingManagerRef={drawingManagerRef}
                managerListener={managerListener}
                mapState={mapState}
                setMapState={setMapState}
              />
            </MapControl>
          )}
          {stopsFiltered && <StopsMarkers stops={stopsFiltered} />}
          <ParcelShopMarkers />
          <AgencyMarkers />
        </Map>
      </div>
    </>
  );
};

CloserMap.propTypes = {};

export default withTranslation()(CloserMap);
