import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useCallback, memo } from 'react';
import { AdvancedMarker, Pin } from '@vis.gl/react-google-maps';

const StopMarker = memo(props => {
  const { stop, setMarkerRef } = props;

  const ref = useCallback(
    marker => setMarkerRef(marker, stop.id),
    [setMarkerRef, stop.id]
  );

  // Convert coordinates object to array if needed
  const coordinates = {
    lat: stop.address.location.coordinates.lat,
    lng: stop.address.location.coordinates.lng
  };

  // Memoize the Pin component configuration
  const pinConfig = {
    background: '#051ab1',
    glyphColor: '#ffce00',
    borderColor: '#fff'
  };

  return (
    <AdvancedMarker position={coordinates} ref={ref}>
      <Pin {...pinConfig} />
    </AdvancedMarker>
  );
});

StopMarker.propTypes = {
  t: PropTypes.func,
  stop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    address: PropTypes.shape({
      location: PropTypes.shape({
        coordinates: PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired
        }).isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  setMarkerRef: PropTypes.func.isRequired
};

export default withTranslation()(StopMarker);
