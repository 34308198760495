import React, { createContext, useEffect, useState } from 'react';
import { login } from '../helpers/API/closerAPI';
import { useSearchParams } from 'react-router-dom';
import ErrorPage from '../components/pages/ErrorPage';
import { isJsonEmpty } from '../helpers/utilsECC';
import MainLayout from '../layouts/MainLayout';
import LoadingPage from '../components/pages/LoadingPage';

export const AuthContext = createContext({ user: {} });
import MapProvider  from './mapProvider';
import {APIProvider} from '@vis.gl/react-google-maps';
// eslint-disable-next-line react/prop-types
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [searchParams] = useSearchParams();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [error, setError] = useState('');
  const [date, setDate] = useState(new Date());

  const tryLoggin = async () => {
    const token = searchParams.get('token');
    if (!token) {
      setIsAuthenticated(false);
      return;
    }
    try {
      const logged = await login(token);
      setUser(logged);
      setIsAuthenticated(true);
    } catch (e) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    if (isJsonEmpty(user)) {
      tryLoggin();
    } else {
      setIsAuthenticated(true);
    }
  }, []);

  if (isAuthenticated === null) {
    return <LoadingPage />;
  }

  if(error){
    return <ErrorPage code={error} />
  }

  return (
    <>
      {isAuthenticated ? (
        <AuthContext.Provider value={{ user, setUser, date, setDate, setError }}>
          <MapProvider>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
              <MainLayout>{children}</MainLayout>
            </APIProvider>
          </MapProvider>
        </AuthContext.Provider>
      ) : (
        <ErrorPage code={401} />
      )}
    </>
  );
};

export default AuthProvider;
