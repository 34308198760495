import { useEffect, useRef } from 'react';

export const useWorker = (workerUrl, onMessage) => {
  const worker = useRef(null);

  useEffect(() => {
    worker.current = new Worker(new URL(workerUrl, import.meta.url), {
      type: 'module'
    });

    worker.current.onmessage = onMessage;

    return () => {
      if (worker.current) {
        worker.current.terminate();
      }
    };
  }, [workerUrl, onMessage]);

  return worker;
};
