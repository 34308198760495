import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';

const FilterLabel = props => {
  const { icon, label } = props;

  return (
    <span
      className={
        'border border-primary rounded-pill p-2 text-primary fs-11 bg-surface-light-2 fw-lighter me-2'
      }
    >
      <span className="material-symbols-outlined fs-9">{icon}</span> {label}
    </span>
  );
};

FilterLabel.propTypes = {
  t: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.string,
};
export default withTranslation()(FilterLabel);
