import { GeometryTypes } from './polygon';
import moment from 'moment/moment';

export const zoneTypes = {
  FIXED: 'fixed',
  TEMPORARY: 'temporary'
};

export const ZoneStatusCodes = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};

export const ZoneDefaultID = 'new-zone';

export const DefaultZoneData = {
  id: ZoneDefaultID,
  geometry: {
    type: GeometryTypes.MultiPolygon,
    coordinates: []
  },
  show: true,
  open: false
};

export const ZoneMovements = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  UP: 'UP',
  DOWN: 'DOWN'
};

export const zonesSort = (a, b) => {
  /*if (a.type === zoneTypes.TEMPORARY && b.type === zoneTypes.FIXED) {
    return -1;
  } else if (a.type === zoneTypes.FIXED && b.type === zoneTypes.TEMPORARY) {
    return 1;
  } */
  /*
  if (a.id === ZoneDefaultID) {
    return -1;
  } else if (b.id === ZoneDefaultID) {
    return 1;
  }*/
  const priorityA = a.priority || 1;
  const priorityB = b.priority || 1;
  if (priorityA === priorityB) {
    const dateA = moment(a.createdAt);
    const dateB = moment(b.createdAt);
    return dateB - dateA;
  }
  return priorityB - priorityA;
};

export const ZoneTemporaryRanges = {
  TODAY: 'Today',
  TODAY_TOMORROW: 'Today & Tomorrow',
};
