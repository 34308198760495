import React, { useContext, useEffect } from 'react';
import CloserMap from '../../componentsGLS/CloserMap';
import {
  APILoadingStatus,
  useApiLoadingStatus
} from '@vis.gl/react-google-maps';

import Zones from '../../componentsGLS/zones';
import { Col, Row } from 'react-bootstrap';
import { AuthContext } from '../../providers/AuthProvider';
import { MapContext } from '../../providers/mapProvider';
import { CreateZoneModes } from '../../dataGLS/CloserModes';
import FiltersBar from '../../componentsGLS/FiltersBar';
const MapPage = () => {
  const status = useApiLoadingStatus();
  const { setError } = useContext(AuthContext);
  const { createZoneMode } = useContext(MapContext);
  useEffect(() => {
    console.log('status', status);
    if (
      status === APILoadingStatus.FAILED ||
      status === APILoadingStatus.AUTH_FAILURE
    ) {
      setError(500);
    }
  }, [status]);

  const mapExtended = () => {
    return createZoneMode === CreateZoneModes.MAP;
  };

  return (
    <>
      {status === APILoadingStatus.LOADED && (
        <div
          className={`position-relative ${mapExtended() ? 'extend-map' : ''}`}
        >
          <Row>
            <Col
              lg={4}
              className={'bg-surface-light px-0 zones-col'}
              style={{ zIndex: '2' }}
            >
              <Zones />
            </Col>
            <Col lg={8} className={'px-0 map-col d-flex flex-column'}>
              <FiltersBar />
              <CloserMap />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default MapPage;
