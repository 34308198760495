import { withTranslation } from 'react-i18next';
import CounterZone from './CounterZone';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo
} from 'react';
import Form from 'react-bootstrap/Form';
import {
  Button,
  Col,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { zoneCalcData } from '../helpers/zoneCalc';
import { objectChange } from '../helpers/utilsECC';
import {
  ZoneDefaultID,
  zoneTypes,
  ZoneTemporaryRanges
} from '../dataGLS/zoneData';
import Select from 'react-select';
import { MapContext } from '../providers/mapProvider';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  SelectComponentsGLS,
  SelectStylesGLS
} from '../helpers/SelectStylesGLS';
import {
  CreateZoneModes,
  MapStates
} from '../dataGLS/CloserModes';
import { debounce } from 'lodash';
import { hideStops } from '../helpers/utils';
const EditZone = props => {
  const { t, setModal } = props;
  const [data, setData] = useState({});
  const {
    zones,
    cancelEditZone,
    saveZone,
    selectedZone,
    setZonesAssign,
    setZones,
    drivers,
    polygons,
    createZoneMode,
    services,
    filterServices,
    setFilterServices,
    filterPostalCodes,
    dateRanges,
    setFilterPostalCodes,
    filterDateRanges,
    setFilterDateRanges,
    mapState
  } = useContext(MapContext);
  const [zone, setZone] = useState(selectedZone);
  const originalZone = useRef();
  const [positionThresholdStops, setPositionThresholdStops] = useState('0$');
  const [triggerUpdateDataList, setTriggerUpdateDataList] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const driverOptions = drivers.map(driver => {
    return { value: driver.code, label: driver.name };
  });
  driverOptions.unshift({ value: '', label: t('Not assigned') });
  const [selectedDriver, setSelectedDriver] = useState(driverOptions[0]);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const [validTime, setValidTime] = useState('');
  useEffect(() => {
    if (!zone) return;
    //IF mode is disabled, reset zone and data
    if (createZoneMode === CreateZoneModes.DISABLED) {
      setZone(null);
      setData({});
      return;
    }
    //When editing zone, update zone with new geometry and stops assigned
    console.log('EditZone Zones useeffect zone', { zone });
    const actualZone = zones.find(mZone => mZone.id === zone.id);
    console.log('EditZone Zones useeffect actual from zones', { actualZone });
    if (!actualZone) return;
    const newZone = {
      ...zone,
      geometry: actualZone.geometry,
      stops: actualZone.stops
    };
    console.log('EditZone Zones useeffect newzone', { newZone });
    setZone(newZone);
  }, [zones, createZoneMode]);

  useEffect(() => {
    console.log('SelectedZone', selectedZone);
    if (!selectedZone) return;
    if (!zone) {
      //If there is no zone set it and if there is not dateRange set default values
      originalZone.current = null;
      const temporary = !selectedZone.temporary
        ? {
            temporary: {
              dateRange: [
                moment(today).format('YYYY-MM-DD'),
                moment(today).format('YYYY-MM-DD')
              ]
            }
          }
        : selectedZone.temporary;
      setZone({
        ...selectedZone,
        temporary
      });
      setData(zoneCalcData(selectedZone));
    } else {
      setData(zoneCalcData(zone));
    }
  }, [selectedZone]);

  useEffect(() => {
    console.log('Edit zone data', data);
    debouncedCalculatePosition();
    return () => {
      debouncedCalculatePosition.cancel();
    };
  }, [data]);

  const setToggleSaveButton = () => {
    console.log('Toggle button', zone);
    if (hideStops) {
      setIsSaveDisabled(
        !zone.name || JSON.stringify(zone) === originalZone.current
      );
    } else {
      setIsSaveDisabled(
        !zone.name ||
          !zone?.thresholds?.stops[0] ||
          zone?.thresholds?.stops[0] < 0 ||
          !zone?.thresholds?.stops[1] ||
          zone?.thresholds?.stops[1] < 0 ||
          JSON.stringify(zone) === originalZone.current
      );
    }
  };

  useEffect(() => {
    if (!zone) return;
    console.log('Edit zone', structuredClone(zone));
    if (!originalZone.current) {
      originalZone.current = JSON.stringify({
        ...zone,
        postalCodes: zone.postalCodes || [],
        services: zone.services || [],
        dateRange: zone.dateRange || ''
      });
      if (zone.driver) {
        setSelectedDriver(
          driverOptions.find(driver => driver.value === zone.driver.code)
        );
      }
      if (zone?.temporary?.dateRange) {
        if (
          moment(today).format('YYYY-MM-DD') === zone?.temporary?.dateRange[1]
        ) {
          setValidTime(ZoneTemporaryRanges.TODAY);
        } else if (
          moment(tomorrow).format('YYYY-MM-DD') ===
          zone?.temporary?.dateRange[1]
        ) {
          setValidTime(ZoneTemporaryRanges.TODAY_TOMORROW);
        }
      }
      if (zone.id !== ZoneDefaultID) {
        setFilterServices(
          zone.services?.map(service => {
            const serv = services.find(serv => serv.code === service);
            return {
              label: serv.name,
              value: serv.code
            };
          }) || []
        );
        setFilterPostalCodes(
          zone.postalCodes?.map(postalCode => ({
            label: postalCode.code,
            value: postalCode.code,
            country: postalCode.country
          })) || []
        );
        // DateRange is single vaalue. Get only if exist
        const dr = dateRanges
          .filter(range => range.code === zone.dateRange)
          .map(range => ({
            value: range.code,
            label: range.name,
            range: range.dateRange.map(value => new Date(value))
          }));
        setFilterDateRanges(dr.length === 1 ? dr[0] : '');
      }
    }
    setData(zoneCalcData(zone));
    setToggleSaveButton();
  }, [zone]);

  const save = () => {
    saveZone(zone);
  };

  const debouncedCalculatePosition = useCallback(
    debounce(() => {
      const min = zone?.thresholds?.stops[0];
      const max = zone?.thresholds?.stops[1];
      if (!min || !max) {
        setPositionThresholdStops('50%');
        return;
      }
      if (data.stopsNumber < min) {
        setPositionThresholdStops('5%');
        return;
      }
      if (data.stopsNumber > max) {
        setPositionThresholdStops('95%');
        return;
      }
      const range = max - min;
      const position = ((data.stopsNumber - min) / range) * 80 + 10;
      setPositionThresholdStops(`${position}%`);
    }, 300),
    [zone, data]
  );

  const updateStopsAssigned = () => {
    setTriggerUpdateDataList(val => !val);
  };
  useEffect(() => {
    if (!zone) return;
    const timeout = setTimeout(() => {
      console.log('Set zone on edit');
      const newZ = zones.map(z => (z.id === zone.id ? zone : z));
      console.log('Zones and modified', {
        zones: structuredClone(zones),
        newZones: structuredClone(newZ)
      });
      /***************************************
       Auto assign zones after first print
       change behaviour to speed up app
       MANUAL ASSIGN
       ***************************************/
      //setZonesAssign(newZ);
      setZones(newZ);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [triggerUpdateDataList]);

  useEffect(() => {
    const handleBeforeUnload = event => {
      // Alert message
      const message = t('Warning reload without save');
      event.returnValue = message; // For some browsers, value necessary
      return message; // For other browsers, value necessary
    };
    // Add listener to window
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Delete listener on dismount component
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const changeDriver = selected => {
    const driver = drivers.find(driver => driver.code === selected.value);
    const newDriver = driver
      ? {
          code: driver.code,
          agency: {
            code: driver.agency.code
          }
        }
      : null;
    setZone({ ...zone, driver: newDriver });
    setSelectedDriver(selected);
  };

  const showModalCancelEdit = () => {
    //IF there's no changes, don't show modal
    if (JSON.stringify(zone) === originalZone.current) {
      cancelEditZone();
      return;
    }
    setModal({
      header: t('There are unsaved changes'),
      body: (
        <>
          <p>{t('Warning reload without save')}</p>
        </>
      ),
      buttonLabel: t('Yes, exit'),
      show: true,
      callback: () => {
        cancelEditZone();
      }
    });
  };

  const cancelDisabled = useMemo(() => {
    return mapState === MapStates.CREATE;
  }, [mapState])

  useEffect(() => {
    if (!zone) return;
    const postalCodes = filterPostalCodes.map(postalCode => ({
      country: postalCode.country,
      code: postalCode.value
    }));
    const services = filterServices.map(service => service.value);
    const dateRange = filterDateRanges?.value || '';
    setZone(prevVal => ({
      ...prevVal,
      services,
      postalCodes,
      dateRange
    }));
    updateStopsAssigned();
  }, [filterServices, filterPostalCodes, filterDateRanges]);

  const handleSave = () => {
    //IF there are polygons, save If not, show modal with warning
    if (zone?.geometry?.coordinates?.length > 0) {
      //Check if there are any polygon overlapping.
      //IF there is a polygon overlapping, show alert and not save
      const polygonOverlapping = polygons.filter(
        polygon =>
          polygon.id.startsWith(zone.polygonID || zone.id) &&
          polygon.overlapping
      );
      if (polygonOverlapping.length > 0) {
        setModal({
          header: t('Polygon overlapping'),
          body: (
            <>
              <p>{t('Warning save with polygon overlapping')}</p>
            </>
          ),
          buttonLabel: t('Understand'),
          show: true,
          showCancelButton: false
        });
        return;
      }
      save();
      return;
    }
    setModal({
      header: t('Empty zone'),
      body: (
        <>
          <p>{t('Warning save without polygons')}</p>
        </>
      ),
      buttonLabel: t('Understand'),
      show: true,
      showCancelButton: false
    });
  };
  //IF mode is not data and zone is not setted, doesn't show
  if (createZoneMode !== CreateZoneModes.DATA || !zone) {
    return <></>;
  }

  return (
    <>
      <Row
        className={`flex-1 overflow-y-scroll m-0 ${
          hideStops ? 'align-content-between' : ''
        }`}
        style={{ height: 'calc(100vh - 78px)' }}
      >
        <Col lg={12}>
          <div className={'p-2'}>
            {!hideStops && (
              <>
                <p className={'fw-bold fs-8'}>{t('Zone info')}</p>
                <div className={'p-2'}>
                  <CounterZone name={t('Shipments')} value={data.stopsNumber} />
                  <CounterZone name={t('Package nº')} value={data.packages} />
                  <CounterZone name={t('Weight (Kg)')} value={data.weight} />
                  <CounterZone name={t('Volume (m3)')} value={data.weight} />
                  <CounterZone name={t('FPE passed')} value={data.FPEPassed} />
                  <CounterZone name={t('FPE today')} value={data.FPEToday} />
                  <CounterZone name={t('FPE future')} value={data.FPEFuture} />
                </div>
              </>
            )}
            <p className={'fw-bold fs-8'}>{t('Zone data')}</p>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label className={'fw-bold'}>{t('Name')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('New zone')}
                value={zone.name}
                name={'name'}
                onChange={e => {
                  objectChange(e, zone, setZone);
                }}
              />
            </Form.Group>
            <p className={'fw-bold'}>{t('Type')}</p>
            <InputGroup className="mb-3">
              <Button
                className={``}
                variant={
                  zone.type !== zoneTypes.FIXED
                    ? 'outline-secondary'
                    : 'primary'
                }
                onClick={() => {
                  setZone({
                    ...zone,
                    type: zoneTypes.FIXED,
                    temporary: undefined
                  });
                  updateStopsAssigned();
                }}
              >
                {t('Zone fixed')}
              </Button>
              <Button
                className={``}
                variant={
                  zone.type !== zoneTypes.TEMPORARY
                    ? 'outline-secondary'
                    : 'primary'
                }
                onClick={() => {
                  setZone({
                    ...zone,
                    type: zoneTypes.TEMPORARY,
                    temporary: {
                      dateRange: [
                        moment(today).format('YYYY-MM-DD'),
                        moment(today).format('YYYY-MM-DD')
                      ]
                    }
                  });
                  updateStopsAssigned();
                }}
              >
                {t('Zone temporary')}
              </Button>
            </InputGroup>
            {zone.type === zoneTypes.TEMPORARY && (
              <>
                <p className={'fw-bold'}>{t('Zone valid time')}</p>
                <Form.Check type="radio" id="today" label={t('Today')}>
                  <Form.Check.Input
                    type="radio"
                    name="validTime"
                    checked={validTime === ZoneTemporaryRanges.TODAY}
                    onChange={() => {
                      setZone({
                        ...zone,
                        temporary: {
                          dateRange: [
                            moment(today).format('YYYY-MM-DD'),
                            moment(today).format('YYYY-MM-DD')
                          ]
                        }
                      });
                      setValidTime(ZoneTemporaryRanges.TODAY);
                    }}
                  />
                  <Form.Check.Label>{`${t('Today')} (${moment(today).format(
                    'DD/MM/YYYY'
                  )})`}</Form.Check.Label>
                </Form.Check>
                <Form.Check type="radio" id="todayTomorrow">
                  <Form.Check.Input
                    type="radio"
                    name="validTime"
                    checked={validTime === ZoneTemporaryRanges.TODAY_TOMORROW}
                    onChange={() => {
                      setZone({
                        ...zone,
                        temporary: {
                          dateRange: [
                            moment(today).format('YYYY-MM-DD'),
                            moment(tomorrow).format('YYYY-MM-DD')
                          ]
                        }
                      });
                      setValidTime(ZoneTemporaryRanges.TODAY_TOMORROW);
                    }}
                  />
                  <Form.Check.Label>{`${t('Today & tomorrow')} (${moment(
                    today
                  ).format('DD/MM/YYYY')} - ${moment(tomorrow).format(
                    'DD/MM/YYYY'
                  )})`}</Form.Check.Label>
                </Form.Check>
              </>
            )}
            <p className={'fw-bold'}>
              {t('Driver')} ({t('Optional')})
            </p>
            <Select
              className={'mb-3'}
              placeholder={t('Select a driver')}
              options={driverOptions}
              styles={SelectStylesGLS}
              components={SelectComponentsGLS}
              value={selectedDriver}
              onChange={value => {
                changeDriver(value);
              }}
            />
            {!hideStops && (
              <>
                <p className={'fw-bold fs-8'}>
                  {t('Threshold management')}&nbsp;
                  <OverlayTrigger
                    overlay={
                      <Tooltip
                        placement={'top'}
                        style={{ position: 'fixed' }}
                        id={'threshold-tooltip'}
                      >
                        {t('Threshold management tooltip')}
                      </Tooltip>
                    }
                  >
                    <span className="material-symbols-outlined text-primary align-middle fs-9 ">
                      info
                    </span>
                  </OverlayTrigger>
                </p>
                <p>{t('Threshold management description')}</p>
                <div className="d-flex justify-content-between">
                  <div
                    style={{ width: '15%', marginLeft: '5%', minWidth: '75px' }}
                  >
                    <Form.Group className="mb-3" controlId="stopsThresholdsMin">
                      <Form.Label className={'fw-bold'}>{t('Min.')}</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={'0'}
                        value={zone?.thresholds?.stops[0] || ''}
                        name={'stopsThresholdsMin'}
                        min={0}
                        onChange={e => {
                          setZone({
                            ...zone,
                            thresholds: {
                              ...zone.thresholds,
                              stops: zone?.thresholds?.stops.map(
                                (value, index) => {
                                  return index === 0
                                    ? parseInt(e.target.value) || 0
                                    : value;
                                }
                              ) || [parseInt(e.target.value), '']
                            }
                          });
                          updateStopsAssigned();
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ width: '15%', minWidth: '75px' }}>
                    <Form.Group className="mb-3" controlId="stopsThresholdsMax">
                      <Form.Label className={'fw-bold'}>{t('Max.')}</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={'0'}
                        value={zone?.thresholds?.stops[1] || ''}
                        name={'stopsThresholdsMax'}
                        min={zone?.thresholds?.stops[0] || 0}
                        onChange={e => {
                          setZone({
                            ...zone,
                            thresholds: {
                              ...zone.thresholds,
                              stops: zone?.thresholds?.stops.map(
                                (value, index) => {
                                  return index === 1
                                    ? parseInt(e.target.value) || 0
                                    : value;
                                }
                              ) || ['', parseInt(e.target.value)]
                            }
                          });
                          updateStopsAssigned();
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div>
                  <div
                    className="progress w-100 mx-2 bg-success"
                    style={{ position: 'relative', height: '5px' }}
                  >
                    <div
                      className="progress-bar bg-primary"
                      style={{ width: '10%', height: '8px' }}
                    ></div>
                    <div
                      className="progress-bar bg-danger"
                      style={{
                        width: '10%',
                        marginLeft: 'auto',
                        height: '10px'
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  className="w-100 mx-2"
                  style={{ position: 'relative', paddingTop: '30px' }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      left: `${positionThresholdStops}`,
                      transform: 'translateX(-50%)',
                      bottom: 0
                    }}
                  >
                    <p className={'fw-bold m-0 lh-1 fs-10 text-center'}>
                      <span className="material-symbols-outlined fs-8">
                        arrow_drop_up
                      </span>
                      <br />
                      {data.stopsNumber}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
        <Col
          lg={12}
          className={'bg-white px-4 py-3'}
          style={{ boxShadow: '0px -1px 4px 0px #00000040' }}
        >
          {cancelDisabled &&
            <OverlayTrigger
              overlay={
                <Tooltip
                  placement={'top'}
                  style={{position: 'fixed'}}
                  id={'edit-cancel-tooltip'}
                >
                  {t('You must unselect polygon creation')}
                </Tooltip>
              }>
              <Button
                variant={'outline-primary'}
                className={'rounded-pill'}
                onClick={showModalCancelEdit}
                disabled={cancelDisabled}
              >
                {t('Cancel')}
              </Button>
            </OverlayTrigger>
          }
          {!cancelDisabled &&
            <Button
              variant={'outline-primary'}
              className={'rounded-pill'}
              onClick={showModalCancelEdit}
              disabled={cancelDisabled}
            >
              {t('Cancel')}
            </Button>
          }
          <Button
            variant={'primary'}
            className={'float-end rounded-pill'}
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            {t('Save zone')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

EditZone.propTypes = {
  t: PropTypes.func,
  setModal: PropTypes.func
};
export default withTranslation()(EditZone);
