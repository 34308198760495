export const CreateZoneModes = {
  DISABLED: 'DISABLED',
  MAP: 'MAP',
  DATA: 'DATA'
};

export const MapStates = {
  DEFAULT: 'Default',
  CREATE: 'Create',
  DELETE: 'Delete'
};
